import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropertyDiscounts from '@rentivo/gatsby-core/src/components/property/PropertyDiscounts/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyFieldsDiscounts,
  selectPropertyPricingVisualSpecialDiscounts
} from '@rentivo/gatsby-core/src/selectors/property';
import { selectSiteCountriesIncludeConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

// TODO: Support fixed discounts when ready.

export const sanitizePropertyDiscounts = discounts => {
  if(!discounts || discounts.length === 0) return [];
  return discounts.map((discount) => {
    let finalDiscount = { ...discount };

    if(discount.discount) {
      finalDiscount = {
        ...finalDiscount,
        ...discount.discount
      }
    }

    finalDiscount.percentageOff = (finalDiscount.amount * 100).toFixed(0);

    return finalDiscount;
  }).sort((a, b) => {
    let comparison = 0;
    if (a.amount > b.amount) {
      comparison = -1;
    } else if (a.amount < b.amount) {
      comparison = 1;
    }
    return comparison;
    // negative = a sorted before b.
    // positive = b sorted before a.
    // 0 = no changes
  }).filter((d, i) => i < 5); // How show max of 5
};

const mapStateToProps = (state, { discountsFromFields, discountsFromPricing }) =>  createSelector(
  selectSiteCountriesIncludeConfig,
  (includeDiscounts) => {
    let finalDiscounts = [];
    if(includeDiscounts) {
      finalDiscounts = (discountsFromFields.length) ? discountsFromFields : discountsFromPricing;
    }
    return {
      discounts: sanitizePropertyDiscounts(finalDiscounts)
    };
  }
);

const propertySelector = createSelector(
  selectPropertyFieldsDiscounts,
  selectPropertyPricingVisualSpecialDiscounts,
  (discountsFromFields, discountsFromPricing) => {
    return {
      discountsFromFields,
      discountsFromPricing
    };
  }
);

const enhance = compose(
  withProperty(propertySelector),
  connect(mapStateToProps),
);

PropertyDiscounts.defaultProps = {

};

export default enhance(PropertyDiscounts);
