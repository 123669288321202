import { defineMessages } from 'react-intl';

export const scope = 'components.property.Price';

export default defineMessages({
  losPrefixLabel: {
    id: `${scope}.los.prefix.label`,
    defaultMessage: 'Book now for',
  },
  losPeriodLabel: {
    id: `${scope}.los.period.label`,
    defaultMessage: 'for your dates',
  },
  averagePeriodLabel: {
    id: `${scope}.average.period.label`,
    defaultMessage: 'for your dates*',
  },
  contactForPriceLabel: {
    id: `${scope}.contact.label`,
    defaultMessage: 'Contact for price',
  },
  nightPeriodLabel: {
    id: `${scope}.night.period.label`,
    defaultMessage: '/ night',
  },
  weekPeriodLabel: {
    id: `${scope}.week.period.label`,
    defaultMessage: '/ week',
  },
  monthPeriodLabel: {
    id: `${scope}.month.period.label`,
    defaultMessage: '/ month',
  },
  priceLowPrefixLabel: {
    id: `${scope}.low.prefix.label`,
    defaultMessage: 'from',
  },
  priceEnquireOnly: {
    id: `${scope}.price.enquireOnly.label`,
    defaultMessage: 'Enquire only',
  },
  finalPriceAccuracyTooltip: {
    id: `${scope}.low.accuracy.label`,
    defaultMessage: 'Amount quoted is for cost of stay tariff, excluding fees and taxes. Final price available on property page',
  },
});
