import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import Price from '@rentivo/gatsby-core/src/components/property/Price/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyFieldsLosCurrency,
  selectPropertyFieldsLosPrice,
  selectPropertyPricingVisualCurrency,
  selectPropertyPricingVisualDiscounts,
  selectPropertyPricingVisualNightlyHigh,
  selectPropertyPricingVisualNightlyLow,
  selectPropertyPricingVisualWeeklyHigh,
  selectPropertyPricingVisualWeeklyLow
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectPropertyPricePeriodConfig,
  selectPropertyPriceTypeConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

const propertySelector = createSelector(
  selectPropertyPriceTypeConfig,
  selectPropertyPricePeriodConfig,
  selectPropertyPricingVisualCurrency,
  selectPropertyPricingVisualWeeklyLow,
  selectPropertyPricingVisualWeeklyHigh,
  selectPropertyPricingVisualNightlyLow,
  selectPropertyPricingVisualNightlyHigh,
  selectPropertyPricingVisualDiscounts,
  selectPropertyFieldsLosPrice,
  selectPropertyFieldsLosCurrency,
  (typeConfig, periodConfig, visualCurrency, weeklyLow, weeklyHigh, nightlyLow, nightlyHigh, discounts, losPrice, losCurrency) => ({
    typeConfig,
    periodConfig,
    visualCurrency,
    weeklyLow,
    weeklyHigh,
    nightlyLow,
    nightlyHigh,
    discounts,
    losPrice,
    losCurrency
  })
);

const enhance = compose(
  withProperty(propertySelector),
);

Price.defaultProps = {
  forcePerNight: undefined,
  shortened: false,
  defaultValue: null,
  fractionDigits: 0,
  showInAccuracyTooltip: false
};

export default enhance(Price);
