import React, { useMemo } from 'react';
import {
  StyledPropertyDiscountsList,
  StyledPropertyDiscountsListItem
} from '@rentivo/gatsby-core/src/components/property/PropertyDiscounts/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faRunning } from '@fortawesome/pro-regular-svg-icons/faRunning';
import { faCrow } from '@fortawesome/pro-regular-svg-icons/faCrow';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import { useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyDiscounts/messages';
import {
  DISCOUNT_EARLY_BIRD,
  DISCOUNT_LAST_MINUTE
} from '@rentivo/gatsby-core/src/components/property/PropertyDiscounts/constants';
import {
  Heading, Text, Box, Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';

const DiscountItem = ({name, category, classification, type, maximumDaysBeforeArrival, minimumDaysBeforeArrival, percentageOff, formatMessage}) => {
  let title;
  let subtitle;
  let icon;
  const variables = { percentage: percentageOff, name, maximumDaysBeforeArrival, minimumDaysBeforeArrival, category };

  switch (classification) {
    case DISCOUNT_EARLY_BIRD:
      title = formatMessage(messages.discountEarlyBirdTitle, variables);
      subtitle = formatMessage(messages.discountEarlyBirdSubtitle, variables);
      icon = <FontAwesomeIcon icon={faCrow} />;
      break;
    case DISCOUNT_LAST_MINUTE:
      title = formatMessage(messages.discountLastMinuteTitle, variables);
      subtitle = formatMessage(messages.discountLastMinuteSubtitle, variables);
      icon = <FontAwesomeIcon icon={faRunning} />;
      break;
    default:
      title = formatMessage(messages.discountTitle, variables);
      subtitle = formatMessage(messages.discountSubtitle, variables) !== ' ' ? formatMessage(messages.discountSubtitle, variables) : null;
      icon = <FontAwesomeIcon icon={faTags} />;
  }

  return (
    <Box mb={2}>
      <Heading as="h5" color="white">{icon} {title}</Heading>
      {subtitle && <Text color="white" className="subtitle">{subtitle}</Text>}
    </Box>
  );
};

const PropertyDiscountsComponent = ({discounts, className, tabIndex, style, popoverPlacement, wrapperStyleProps = {}, ...rest}) => {
  if(!discounts || discounts.length === 0) return null;
  popoverPlacement = (popoverPlacement) ? popoverPlacement : 'bottom-start';
  const { formatMessage } = useIntl();

  const content = useMemo(() => {

    return (
      <Stack spacing="md">
        {discounts.map((discount, i) => <DiscountItem formatMessage={formatMessage} key={i} {...discount}/>)}
        <Box borderTop="1px solid" borderColor="whiteAlpha.200" pt={1}>{formatMessage(messages.discountsPopoverFooterText)}</Box>
      </Stack>
    );
  }, [discounts]);

  return (
    <TouchTooltip
      hasArrow
      placement={popoverPlacement}
      label={
        <Box p={2}>
          {content}
        </Box>
      }
      wrapperStyleProps={wrapperStyleProps}
    >
      <Button size="small" type="white" display="block" textOverflow="ellipsis" overflow="hidden" minW="auto" minH="auto" tabIndex={tabIndex} leftIcon={<FontAwesomeIcon icon={faTags} />} className={className} {...rest}>
        {formatMessage(messages.maxPercentageDiscount, { percentage: discounts[0].percentageOff, discountCount: discounts.length})}
      </Button>
    </TouchTooltip>
  );
};

export default PropertyDiscountsComponent;


/*
<StyledPropertyDiscountsList>
        {discounts.map((discount, i) => <DiscountItem formatMessage={formatMessage} key={i} {...discount}/>)}
        <footer>
          {formatMessage(messages.discountsPopoverFooterText)}
        </footer>
      </StyledPropertyDiscountsList>
 */

/*
<Popover trigger="click" isLazy closeOnBlur={false}>
        <PopoverTrigger>
          <Button size="small" type="white" tabIndex={tabIndex} leftIcon={<FontAwesomeIcon icon={faTags} />} {...rest}>
            {formatMessage(messages.maxPercentageDiscount, { percentage: discounts[0].percentageOff, discountCount: discounts.length})}
          </Button>
        </PopoverTrigger>
        <PopoverContent maxW="225px">
          <PopoverArrow />
          <PopoverHeader fontWeight="semibold">{formatMessage(messages.discountsPopoverTitle)}</PopoverHeader>
          <PopoverBody>{content}</PopoverBody>
        </PopoverContent>
      </Popover>
 */

/*
<Popover content={content} title={formatMessage(messages.discountsPopoverTitle)} trigger="hover" placement={popoverPlacement}>
        <Button size="small" type="secondary" tabIndex={tabIndex} icon={<FontAwesomeIcon icon={faTags} />}>
          {formatMessage(messages.maxPercentageDiscount, { percentage: discounts[0].percentageOff, discountCount: discounts.length})}
        </Button>
      </Popover>
 */
