import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyDiscounts';

export default defineMessages({
  maxPercentageDiscount: {
    id: `${scope}.maxPercentageDiscount.button.text`,
    defaultMessage: '{percentage}% off ({discountCount} {discountCount, plural, one {offer} other {offers}})',
  },
  discountsPopoverTitle: {
    id: `${scope}.popover.title`,
    defaultMessage: 'Discounts',
  },
  discountTitle: {
    id: `${scope}.title`,
    defaultMessage: '{name}',
  },
  discountSubtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: ' ',
  },
  discountEarlyBirdTitle: {
    id: `${scope}.earlyBird.title`,
    defaultMessage: 'Early bird {percentage}% off',
  },
  discountEarlyBirdSubtitle: {
    id: `${scope}.earlyBird.subtitle`,
    defaultMessage: 'Applies if you book {minimumDaysBeforeArrival} days before arrival.',
  },
  discountLastMinuteTitle: {
    id: `${scope}.lastMinute.title`,
    defaultMessage: 'Last minute {percentage}% off',
  },
  discountLastMinuteSubtitle: {
    id: `${scope}.lastMinute.subtitle`,
    defaultMessage: 'Applies if you book within {maximumDaysBeforeArrival} days of arrival.',
  },
  discountsPopoverFooterText: {
    id: `${scope}.popover.footer.text`,
    defaultMessage: 'Check to see if these discounts apply by selecting the property and getting a quote.',
  }
});
