import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import GenericPrice from '@rentivo/gatsby-core/src/components/generic/Price';
import {
  PRICE_PERIOD_NIGHTLY,
  PRICE_PERIOD_WEEKLY,
  PRICE_TYPE_AVERAGE,
  PRICE_TYPE_LOW,
  PRICE_TYPE_RANGE
} from '@rentivo/gatsby-core/src/constants/property';
import messages from '@rentivo/gatsby-core/src/components/property/Price/messages';
import { Text } from '@chakra-ui/react';
import Info from '@rentivo/gatsby-core/src/components/ui/Info';
import { ConditionalWrapper } from '@rentivo/gatsby-core/index';

const PriceComponent = ({forcePerNight, typeConfig, defaultValue, periodConfig, visualCurrency, showInAccuracyTooltip, weeklyLow, weeklyHigh, nightlyLow, nightlyHigh, fractionDigits, shortened, discounts, losPrice, losCurrency}) => {
  const { formatMessage } = useIntl();

  const {
    showTooltip,
    priceProps,
    inAccuracyMessage
  } = useMemo(() => {

    const inAccuracyMessage = formatMessage(messages.finalPriceAccuracyTooltip);

    let priceLow = null;
    let priceHigh = null;
    let priceAverage = null;
    let showTooltip = false;

    let priceProps = { currency: visualCurrency };

    // Set period vars
    if(periodConfig === PRICE_PERIOD_NIGHTLY) {
      priceLow = nightlyLow;
      priceHigh = nightlyHigh;
      priceAverage = (priceLow + priceHigh) / 2;
      priceProps.periodSuffix = <FormattedMessage {...messages.nightPeriodLabel}/>;
    }

    if(periodConfig === PRICE_PERIOD_WEEKLY) {
      priceLow = weeklyLow;
      priceHigh = weeklyHigh;
      priceAverage = (priceLow + priceHigh) / 2;
      priceProps.periodSuffix = <FormattedMessage {...messages.weekPeriodLabel}/>;
    }

    // Assign correct vars for price type
    switch (typeConfig) {
      case PRICE_TYPE_RANGE:
        priceProps.price = priceLow;
        priceProps.toPrice = priceHigh;
        showTooltip = true;

        if(priceProps.price === priceProps.toPrice) {
          delete priceProps.toPrice;
        }

        break;

      case PRICE_TYPE_LOW:
        priceProps.prefix = <FormattedMessage {...messages.priceLowPrefixLabel}/>;
        priceProps.price = priceLow;
        priceProps.toPrice = null;
        break;

      case PRICE_TYPE_AVERAGE:
        priceProps.price = priceAverage;
        priceProps.toPrice = null;
        priceProps.periodSuffix = <FormattedMessage {...messages.averagePeriodLabel}/>;
        break;

      default:
        priceProps.price = priceLow;
        priceProps.toPrice = null;
        showTooltip = false;
        break;
    }

    if(losPrice !== null && losCurrency !== null) {
      delete priceProps.toPrice;
      delete priceProps.periodSuffix;
      priceProps.price = losPrice;
      priceProps.currency = losCurrency;
      showTooltip = true;
      //priceProps.periodSuffix = <FormattedMessage {...messages.losPeriodLabel}/>;
      priceProps.prefix = <FormattedMessage {...messages.losPrefixLabel}/>;
    }

    if(shortened) {
      showTooltip = false;
      delete priceProps.toPrice;
      delete priceProps.prefix;
      delete priceProps.periodSuffix;
    }

    if(forcePerNight && forcePerNight > 0) {
      showTooltip = false;
      delete priceProps.toPrice;
      priceProps.price = forcePerNight;
    }

    if(!inAccuracyMessage || inAccuracyMessage === " " || inAccuracyMessage === "") {
      showTooltip = false;
    }

    if(priceProps.price === priceProps.toPrice || priceProps.price === 0 || !priceProps.toPrice) {
      showTooltip = false;
    }

    if(showInAccuracyTooltip === false) {
      showTooltip = false;
    }

    return {
      priceLow,
      priceHigh,
      priceAverage,
      showTooltip,
      priceProps,
      inAccuracyMessage
    }
  }, [showInAccuracyTooltip, forcePerNight, typeConfig, defaultValue, periodConfig, visualCurrency, weeklyLow, weeklyHigh, nightlyLow, nightlyHigh, fractionDigits, shortened, discounts, losPrice, losCurrency]);

  return (
    <Text as="span" isTruncated mb={0}>
      <ConditionalWrapper wrapper={(children) => <Info title={inAccuracyMessage} tooltip={{position: 'bottom', hasArrow: true}}>{children}</Info>} condition={showTooltip}>
        <GenericPrice
          {...priceProps}
          periodSeparator=""
          fractionDigits={fractionDigits}
          defaultValue={(defaultValue) ? defaultValue : <FormattedMessage {...messages.priceEnquireOnly}/>}
        />
      </ConditionalWrapper>
    </Text>
  );
};

export default PriceComponent;
